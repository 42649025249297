<template>
  <div class="floating-tool-container">
    <div class="col col1" :class="showType">
      <img src="../assets/home/pad/工具.svg" alt="" srcset="" />
    </div>
    <div class="col col2" :class="showType">
      <div class="wrap">
        <div
          :class="{ item: true, selected: item.selected }"
          @click="onSelected(index)"
          v-for="(item, index) in tools"
          :key="index"
        >
          <img :src="item.img" alt="" srcset="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="col col3" @click="showTypeToogle" :class="showType">
      <img src="../assets/home/pad/收起.svg" alt="" srcset="" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      showType: "open", // fold
      tools: [
        {
          name: "工具",
          selected: true,
          img: require("../assets/home/pad/工具2.svg"),
        },
        {
          name: "工具",
          selected: false,
          img: require("../assets/home/pad/工具3.svg"),
        },
      ],
    };
  },
  methods: {
    showTypeToogle() {
      this.showType = this.showType === "open" ? "fold" : "open";
    },
    onSelected(index) {
      this.tools.forEach((item) => {
        item.selected = false;
      });
      this.tools[index].selected = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.floating-tool-container {
  display: flex;
  height: 73px;
  line-height: 73px;
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 27px;
  left: 0;
  font-size: 23px;
  color: #f7c829;
  img {
    width: 38px;
  }
  .col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col1 {
    width: 92px;

    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      height: 40%;
      border-right: 1px solid #f7c829;
    }
    &.fold {
      &:after {
        display: none;
      }
    }
  }
  .col2 {
    transition: all 0.3s;
    overflow: hidden;
    width: 335px;
    .wrap {
      display: flex;
      min-width: 289px;
      padding: 0 23px;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 128px;
        height: 61px;
        padding: 0 7px;
        border: 1px solid rgba(0, 0, 0, 0);
        span {
          margin-left: 9px;
        }
        &.selected {
          background: rgba(247, 200, 41, 0.1);
          border: 1px solid #f7c829;
        }
      }
    }

    &.fold {
      transition: width 0.4s;
      width: 0;
    }
  }
  .col3 {
    border-left: 1px solid #f7c829;
    width: 61px;
    text-align: center;
    &.fold {
      img {
        transform: rotateY(180deg);
      }
    }
  }
}
</style>
