<template>
  <div
    class="search-box"
    v-if="searchBoxPadVisible"
    v-clickoutside="handleClose"
  >
    <div class="wrapper">
      <img src="~@/assets/search-img.png" alt="" srcset="" />
      <div class="input">
        <input type="text" v-model="value" placeholder="请输入要查找的内容" />
      </div>
      <div class="search-list" v-if="list && list.length">
        <div class="title">文书</div>
        <div
          :class="{ item: true, selected: item.selected }"
          v-for="(item, index) in list"
          :key="index"
          @click="onSelected(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const clickoutside = {
  // 初始化指令
  bind(el, binding) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    setTimeout(() => {
      document.addEventListener("click", documentHandler);
    });
  },
  update() {},
  unbind(el) {
    // 解除事件监听
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};
export default {
  name: "SearchBox",
  directives: { clickoutside },
  data() {
    return {
      value: null,
      list: [],
    };
  },
  computed: {
    searchBoxPadVisible() {
      return this.$tis.bus.searchBoxPadVisible;
    },
  },

  methods: {
    handleClose(e) {
      this.$tis.bus.searchBoxPadVisible = false;
    },
    onSelected(index) {
      this.list.forEach((item) => {
        item.selected = false;
      });
      this.list[index].selected = true;
    },
  },
  watch: {
    searchBoxPadVisible() {
      this.list = [];
      this.value = "";
    },
    value(val) {
      this.list = [];
      if (val) {
        for (let i = 0; i < 3; i++) {
          this.list.push({
            name: `搜索结果-${val}`,
            selected: false,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.search-box {
  position: fixed;
  right: 123px;
  width: 616px;
  top: 107px;
  border: 1px solid #fadf6d;
  z-index: 93;
  .input {
    z-index: 1;
    width: 100%;
    top: 0;
    background: #30302e;
    height: 69px;
    input {
      height: 100%;
      width: 100%;
      font-size: 24px;
      padding: 0 23px;
      box-sizing: border-box;
      background: none;
      color: #fff;
    }
  }
  .search-list {
    background: #11120f;
    font-size: 24px;
    // padding: 15px;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.8);
    .title {
      padding: 30px;
    }
    .item {
      height: 77px;
      line-height: 77px;
      padding-left: 26px;
      padding-left: 46px;
      &.selected,
      &:hover {
        background: linear-gradient(86deg, #8a773c, transparent);
      }
    }
  }
  img {
    position: absolute;
    display: none;
    opacity: 0.5;
  }
}
</style>
