<template>
  <div
    class="applicationInterface-container"
    v-if="applicationInterface.visible"
  >
    <div class="header">
      <transition name="slide-fade">
        <div class="status-bar-container" v-show="visible && !isFullScreen">
          <div class="col col2">{{ applicationInterface.name }}</div>
          <div class="col col3">
            <OperationalInformationMobile />
          </div>
          <div class="col col4">
            <ActionBarMobile />
          </div>
          <div class="col col5">
            <span class="item-tool close-btn" @click="closeDialog">
              <img src="../assets/home/pad/关机.svg" />
            </span>
          </div>
        </div>
      </transition>
      <img
        class="exitFullScreen"
        src="../assets/exitFullScreen.png"
        v-show="isFullScreen"
        @click="onExitFullScreen"
      />
    </div>

    <div class="content">
      <div class="wrap-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import OperationalInformationMobile from "./OperationalInformationMobile.vue";
import ActionBarMobile from "./ActionBarMobile.vue";
export default {
  components: {
    OperationalInformationMobile,
    ActionBarMobile,
  },
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$tis.bus.applicationInterface.visible = false;
      this.$tis.bus.alarmMainWineowVisible = false;
      this.$tis.bus.alarmSendVisible = false;
      this.$tis.hiddenAllVisible();
    },
    onExitFullScreen() {
      this.$tis.fullScreen(false);
    },
  },
  computed: {
    applicationInterface() {
      return this.$tis.bus.applicationInterface;
    },
    StatusBar() {
      return this.$tis.layouts.StatusBar;
    },
    visible() {
      return !!this.StatusBar;
    },
    isFullScreen() {
      return this.$tis.isFullScreen();
    },
  },
  watch: {
    applicationInterface: {
      handler(val) {
        if (!val.visible) {
          this.$tis.bus.applicationInterfaceApps.forEach((key) => {
            this.$tis.bus[key] = false;
          });
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.exitFullScreen {
  top: 11px;
  right: 11px;
  z-index: 200;
  position: fixed;
  width: 31px;
  cursor: pointer;
  float: right;
  margin: 11px;
}
.applicationInterface-container {
  background: url(../assets/app-bg.png) no-repeat;
  background-size: 100% 100%;
  position: fixed;
  z-index: 92;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    position: fixed;
    height: 80px;
    z-index: 92;
    width: 100%;
    & > div {
      height: 80px;
      width: 100%;
      display: flex;
      overflow: hidden;
      align-items: center;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 90px;
        background-image: url("../assets/img31.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: -1;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -2;
        background-image: url("../assets/img32.png");
        background-repeat: no-repeat;
      }
      .col2 {
        font-size: 29px;
        color: #fff;
        margin-left: 90px;
        margin-right: 40px;
      }
      .col3 {
        flex: 1;
        height: 100%;
      }
      .col5 {
        margin-right: 15px;
      }
    }
  }
  .content {
    flex: 1;
    padding-top: 80px;
    background: rgba(0, 0, 0, 0.8);
    .wrap-content {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-7px);
  opacity: 0;
}
.close-btn {
  img {
    height: 30px;
    position: relative;
    top: 2px;
  }
}
</style>
