<template>
  <div v-if="visible" v-clickoutside="handleClose">
    <VoiceAssistantMobile />
  </div>
</template>

<script>
import VoiceAssistantMobile from "../../components/VoiceAssistantMobile.vue";
const clickoutside = {
  // 初始化指令
  bind(el, binding) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    setTimeout(() => {
      document.addEventListener("click", documentHandler);
    });
  },
  update() {},
  unbind(el) {
    // 解除事件监听
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};

export default {
  components: { VoiceAssistantMobile },
  data() {
    return {};
  },

  directives: { clickoutside },

  methods: {
    handleClose() {
      this.$tis.toggleVoiceBox();
    },
  },
  mounted() {},
  computed: {
    visible() {
      return this.$tis.bus.visibleVoiceBox;
    },
  },
};
</script>

<style lang="scss" scoped></style>
