<template>
  <div class="dialog dialog1 message-reply-dialog" v-if="messageDetailVisible">
    <div class="wrap"></div>
    <div class="dialog-content" v-clickoutside="handleClose">
      <div class="title">
        指挥决策
      </div>
      <div class="close-btn" @click.stop="onCloseDialog">
        <img src="~@/assets/img_090802.png" alt="" srcset="" />
      </div>
      <div class="text">
        描述信息描述信息描述信息描述信息描述信息描述信息描述信描述信息描述信息。描述信息描述信息描述信息描述信息描述信息描述信息描述信描述信息描述信息。
      </div>
      <div class="footer">
        <!-- <div class="div1">回复内容</div> -->
        <tis-input placeholder="回复内容" v-model="value"></tis-input>
      </div>
    </div>
  </div>
</template>
<script>
const clickoutside = {
  // 初始化指令
  bind(el, binding) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    setTimeout(() => {
      document.addEventListener("click", documentHandler);
    });
  },
  update() {},
  unbind(el) {
    // 解除事件监听
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};
export default {
  name: "MessageBox",
  directives: { clickoutside },
  data() {
    return {
      value: "",
    };
  },
  mounted() {},
  methods: {
    onCloseDialog() {
      this.$tis.bus.messageDetailVisible = false;
    },
    handleClose() {
      this.$tis.bus.messageDetailVisible = false;
    },
  },
  computed: {
    messageDetailVisible() {
      return this.$tis.bus.messageDetailVisible;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog.dialog1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 92;
  .wrap {
    background: #fff;
    opacity: 0.1;
    width: 100%;
    height: 100%;
  }
  .dialog-content {
    padding: 24px;
    box-sizing: border-box;
    width: 458px;
    height: 331px;
    position: absolute;
    background: #292929;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    .title {
      font-size: 23px;
      color: #ffffff;
      margin-bottom: 15px;
    }
    .text {
      font-family: HiraginoSansGB-W3;
      font-size: 21px;
      color: #ffffff;
      line-height: 32px;
    }
    .close-btn {
      position: absolute;
      right: 23px;
      top: 23px;
    }
    .footer {
      position: absolute;
      bottom: 15px;
      width: 100%;
      box-sizing: border-box;
      left: 0;
      padding: 0 26px;
      .el-input {
        width: 100%;
        height: 100%;
        padding: 0;
        ::v-deep input {
          background: none;
          border: none;
          width: 100%;
          height: 100%;
          font-size: 23px;
        }
      }
      div {
        height: 61px;
        line-height: 61px;
        box-sizing: border-box;
        padding: 0 23px;
        opacity: 0.5;
        font-family: HiraginoSansGB-W3;
        font-size: 21px;
        color: #ffffff;
        background: #3b3b3b;
        border: 1px solid rgba(255, 255, 255, 0.63);
        width: 100%;
      }
    }
  }
}
</style>
