<template>
  <div class="tisc-login-page">
    <div class="tisc-login-page-header">
      <h1>轻型数字化合成旅指挥信息系统</h1>
    </div>
    <div class="tisc-login-wrapper">
      <div class="tisc-login" v-show="toLogin">
        <div class="tisc-login-header">用户登录</div>
        <div class="tisc-login-inpt">
          <el-autocomplete
            class="inline-input tisc-login-input-username"
            popperClass="username-drop-down-box"
            v-model="username"
            :fetch-suggestions="querySearch"
            placeholder="用户名"
            @select="handleSelect"
          />
          <span v-if="visible" class="iconfont absol" @click="tabpass">
            &#xe611;
          </span>
          <span v-else class="iconfont absol" @click="tabpass">&#xe610;</span>
          <el-input
            class="tisc-login-input-pwd"
            v-if="visible"
            type="password"
            v-model="password"
            placeholder="密码"
          ></el-input>
          <el-input
            v-else
            type="text"
            class="inline-input tisc-login-input-password"
            v-model="password"
            placeholder="密码"
          ></el-input>
          <div class="divMassage" v-show="massageTo">密码错误，请重新输入</div>
        </div>
        <div class="tisc-login-btns">
          <button class="tisc-cancel-btn">取&nbsp;&nbsp;消</button>
          <button type="primary" class="tisc-login-btn" @click.stop="goLogin">
            下一步
          </button>
        </div>
      </div>
    </div>

    <div class="zhang-wrapper" v-show="addLogin">
      <div class="zhang-gologin">
        <div class="zhang-goheader">席位选择</div>
      </div>
      <div class="zhang-content">
        <m-picker
          item-height="3.8vw"
          :visible-item-count="4"
          :swipe-duration="400"
          :columns="data.map((item) => item.name)"
        />
      </div>
      <div class="footer2">
        <button @click="onCancel">取消</button>
        <button class="login-btn" @click="login">
          确认
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login-page",
  data() {
    return {
      massageTo: false,
      toLogin: true,
      addLogin: false,
      username: "",
      password: "",
      suggestions: [
        { value: "李一洋", id: "11111" },
        {
          value: "球队助教1",
          id: "22222",
        },
      ],
      data: [
        {
          id: 0,
          name: "勒布朗·詹姆斯",
        },
        {
          id: 1,
          name: "史蒂芬·库里",
        },
        {
          id: 2,
          name: "扬尼斯·阿德托昆博",
        },
        {
          id: 3,
          name: "卢卡·东契奇",
        },
        {
          id: 4,
          name: "凯文·杜兰特",
        },
        {
          id: 5,
          name: "勒布朗·詹姆斯",
        },
        {
          id: 6,
          name: "NBA 球星 6",
        },
        {
          id: 7,
          name: "NBA 球星 7",
        },
        {
          id: 8,
          name: "NBA 球星 8",
        },
        {
          id: 9,
          name: "NBA 球星 9",
        },
        {
          id: 10,
          name: "NBA 球星 10",
        },
      ],
      act: 0,
      scrollTop: null,
      scrollDefaultTop: 0,
      scrollLiHeight: 0,
      list: [],
      visible: true,
    };
  },
  methods: {
    tabpass() {
      this.visible = !this.visible;
    },
    //显示隐藏
    goLogin() {
      //密码框判断
      if (this.password == "test") {
        this.addLogin = !this.addLogin;
        this.toLogin = !this.toLogin;
        this.$nextTick(() => {
          this.scrollDefaultTop = document.querySelector(
            ".zhang-content .box"
          ).offsetTop;
          this.scrollTop = this.scrollDefaultTop;
          this.scrollLiHeight = document.querySelector(
            ".zhang-btn"
          ).offsetHeight;
        });
      } else {
        this.massageTo = true;
        setTimeout(() => {
          this.massageTo = false;
        }, 3000);
      }
    },
    onCancel() {
      this.addLogin = !this.addLogin;
      this.toLogin = !this.toLogin;
    },
    login() {
      // 登录调用接口逻辑
      this.$tis.axios
        .post("/Seatselection", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          console.log("登录成功", res);
          this.$emit("login-success", {
            username: this.username,
            password: this.password,
          });
        })
        .catch((err) => {
          console.log("登录失败", err);
          this.$emit("login-fail", {
            username: this.username,
            password: this.password,
          });
        });
      // 登录调用接口逻辑
    },
    cancel() {
      this.username = "";
      this.password = "";
      this.$emit("cancel");
    },
    querySearch(query, cb) {
      cb(this.suggestions);
    },
    handleSelect(item) {
      console.log("选择用户：", item);
    },
    changeTheme() {
      const styleLink = document.getElementById("tis-design-theme");
      const href = styleLink.getAttribute("href");

      if (href.indexOf("theme-chalk") !== -1) {
        // 变成 theme-blue
        styleLink.setAttribute(
          "href",
          "http://tis.inscloudtech.com:8000/tis-ui/theme-blue/index.css"
        );
      } else {
        // 变回 theme-chalk
        styleLink.setAttribute(
          "href",
          "http://tis.inscloudtech.com:8000/tis-ui/theme-chalk/index.css"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tisc-login-page {
  background-image: url("../assets/mobile-login-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &-header {
    text-align: center;
    margin-top: 5%;
    h1 {
      font-size: 54px;
      color: #ffffff;
      letter-spacing: 3px;
      margin: 0;
    }
    h2 {
      font-size: 23px;
      color: #acd5ff;
      letter-spacing: 5px;
      opacity: 0.3;
      margin: 0;
    }
  }
}
.tisc-login-wrapper {
  width: 497px;
  height: 330px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tisc-login-car-info {
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 19px;
}

.tisc-login {
  background: transparent;
  width: 500px;
  height: 347px;
  margin: 0 auto;
  padding: 3px 35px;
  background-image: url("../assets/mobile-login-layer.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  .tisc-login-header {
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 23px;
    margin-bottom: 45px;
    color: #ffffff;
  }
  .tisc-login-inpt {
    margin-bottom: 40px;
  }

  &-header {
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 23px;
    margin-bottom: 28px;
    color: #ffffff;
  }

  &-inpt {
    margin-bottom: 30px;
    .tisc-login-input-username {
      margin-bottom: 20px;
      height: 64px;
      width: 100%;
      ::v-deep .el-input__inner {
        height: 64px;
        font-size: 24px;
        padding: 0 15px;
      }
    }

    .tisc-login-input-pwd,
    .tisc-login-input-password {
      height: 46px;
      margin-bottom: 20px;
      ::v-deep .el-input__inner {
        height: 64px;
        font-size: 24px;
        padding: 0 15px;
      }
    }

    input {
      height: 46px;
    }
  }

  // &-btns {
  //   display: flex;
  //   justify-content: space-between;

  //   & > button {
  //     width: 162px;
  //     height: 46px;
  //     font-size: 19px;
  //     border-radius: 0;
  //   }

  //   .tisc-cancel-btn {
  //     background-color: #eee;
  //     border: none;
  //     color: #000;
  //   }
  //   .tisc-cancel-btn:hover {
  //     cursor: pointer;
  //     color: #ffe88b;
  //   }
  //   .tisc-cancel-btn:hover {
  //     color: #ffe88b;
  //   }

  //   .tisc-login-btn {
  //     background-image: url("../assets/btn.png");
  //     background-repeat: no-repeat;
  //     background-size: cover;
  //     font-size: 19px;
  //     // color: #382c0c;
  //     border: none;
  //   }
  // }
  .tisc-login-btns {
    display: flex;
    justify-content: space-between;
    button {
      width: 194px;
      height: 60px;
      background: #5d81aa;
      font-size: 24px;
      color: #ffffff;
      border: none;
    }
  }
}
.btn:hover {
  color: #ffe88b;
}

.tisc-login-btn:hover {
  color: #ffe88b;
  cursor: pointer;
}
.zhang-wrapper {
  width: 497px;
  height: 330px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zhang-gologin {
  background: transparent;
  width: 500px;
  height: 347px;
  margin: 0 auto;
  padding: 3px 64px;
  background-image: url("../assets/mobile-login-layer.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  .zhang-goheader {
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 23px;
    margin-bottom: 28px;
    color: #ffffff;
  }
}
.zhang-content {
  width: 94%;
  height: calc(100% - 111px);
  position: absolute;
  top: 26px;
  left: 3%;
  z-index: 999;
  overflow: hidden;
  .box {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 90px;
    right: -16px;
    bottom: 0;

    .li {
      width: 70%;
      line-height: 50px;
      height: 50px;
      opacity: 0.3;
      font-size: 21px;
      color: #6cc8ff;
      text-align: center;
      list-style: none;
      z-index: 999;
      position: relative;
      left: 90px;
    }
    .li:hover {
      cursor: pointer;
    }
    li.act {
      opacity: 0.9;
      color: #ffe55e;
      transition: all 0.3s;
    }
  }

  .van-picker {
    background: none;
    position: relative;
    height: 238px;
    ::v-deep .van-picker__mask {
      display: none;
    }
    ::v-deep .van-picker__frame {
      // border: 1px solid red;
      &:after {
        content: "";
        background-image: url(../assets/mobile-selectet-icon.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        border: none;
        z-index: 111;
        height: 65px;
        transform: scale(1);
        width: 100%;
        left: 0;
        top: -12px;
        // border: 1px solid blue;
      }
    }
    ::v-deep .van-picker-column {
      li {
        font-size: 19px;
        color: #6cc8ff;
        opacity: 0.6;
        &.van-picker-column__item--selected {
          color: #ffe55e;
          opacity: 1;
          font-size: 22px;
        }
      }
    }
  }
}
.footer2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 34px;
  position: absolute;
  bottom: 11px;
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
  button {
    border: none;
    background: #5d81aa;
    width: 45%;
    height: 60px;
    font-size: 24px;
    text-align: center;
    color: #fff;
  }
  .login-btn {
    color: #262626;
    background-image: url("../assets/btn.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
.btn {
  background: none;
  border: none;
  font-size: 19px;
  color: #ffe55e;
  position: fixed;
  left: 374px;
  top: 179px;
  z-index: 999;
}
.btn:hover {
  cursor: pointer;
}
.mobile-selectet-icon {
  width: 445px;
  height: 67px;
  position: fixed;
  left: 20px;
  top: 100px;
  z-index: -999;
}
.zhang-btn {
  // text-align: left;
  // padding-left: 96px;
}

::v-deep
  .el-input__icon
  iconfont
  icon-yincang
  .el-input__icon
  iconfont
  icon-xianshi {
  margin-right: 28px;
  z-index: 9999;
}

.absol {
  position: absolute;
  z-index: 99999;
  top: 177px;
  right: 50px;
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 30px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  color: #fff;
}
.divMassage {
  width: 100%;
  height: 28px;
  position: absolute;
  top: 230px;
  left: 0;
  z-index: 999;
  border-radius: 9px;
  color: red;
  line-height: 28px;
  text-align: center;
  font-size: 19px;
}
</style>
<style lang="scss">
.username-drop-down-box {
  li {
    font-size: 21px;
    line-height: 40px;
  }
}
</style>
