<template>
  <div class="">
    <div class="control-panel-pad" v-if="controlPanelPadVisible">
      <div class="wrap">
        <div class="head">
          <div class="left">
            <div class="tabs">
              <div
                :class="{ item: true, selected: item.selected }"
                v-for="(item, index) in tabs"
                :key="index"
                @click="onSelected(index)"
              >
                <div class="label">{{ item.name }}</div>
              </div>
              <!-- <div class="item" @click="showFloatingwindow">
            <div class="label">添加浮窗</div>
          </div> -->
            </div>
          </div>
          <div class="right">
            <div class="input">
              <img src="../assets/home/搜索.svg" />
              <input type="search" placeholder="搜索应用" />
            </div>
          </div>
        </div>
        <div class="content" v-show="tabIndex === 1"></div>
        <div class="content" v-show="tabIndex === 0">
          <m-swipe class="my-swipe" indicator-color="white">
            <m-swipe-item>
              <div class="list">
                <div class="row">
                  <div
                    class="item"
                    :class="{
                      selected: item.selected && operationStatus == '',
                      highlight: styleHighlight(index),
                    }"
                    @click="onApplicationInterface(item.name)"
                    v-for="(item, index) in appList"
                    :key="index"
                  >
                    <i
                      v-if="operationStatus === 'edit'"
                      class="close-btn iconfont icon-a-304cuowuguanbiquxiao-yuankuang"
                      @click.stop="handleDelete(index)"
                    >
                    </i>
                    <div class="icon">
                      <img :src="item.icon" alt="" />
                    </div>
                    <div class="title">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </m-swipe-item>
            <m-swipe-item>
              <div class="list">
                <div class="row">
                  <div
                    class="item"
                    :class="{
                      selected: item.selected && operationStatus == '',
                      highlight: styleHighlight(index),
                    }"
                    @click="onApplicationInterface(item.name)"
                    v-for="(item, index) in appList"
                    :key="index"
                  >
                    <i
                      v-if="operationStatus === 'edit'"
                      class="close-btn iconfont icon-a-304cuowuguanbiquxiao-yuankuang"
                      @click.stop="handleDelete(index)"
                    >
                    </i>
                    <div class="icon">
                      <img :src="item.icon" alt="" />
                    </div>
                    <div class="title">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </m-swipe-item>
          </m-swipe>
        </div>
        <div class="operation">
          <el-button v-if="!operationStatus" @click="handleEdit"
            >编辑</el-button
          >
          <template v-else>
            <el-button>添加</el-button>
            <el-button @click="handleSave">保存</el-button>
          </template>
        </div>
      </div>
      <div class="dialog dialog2" v-if="dialogVisible">
        <div class="mask" @click="closeDialog"></div>
        <div class="dialog-body">
          <div class="title">反坦克计划</div>
          <div class="content">
            <div class="list">
              <div class="item" @click="onApplicationInterface('反坦克计划')">
                <div class="icon">
                  <img src="./../assets/icon/1.svg" alt="" />
                </div>
                <div class="title">反坦克计划1</div>
              </div>
              <div class="item" @click="onApplicationInterface('计划管理')">
                <div class="icon">
                  <img src="./../assets/icon/2.svg" alt="" />
                </div>
                <div class="title">计划管理</div>
              </div>
              <div class="item" @click="onApplicationInterface('作战统筹')">
                <div class="icon">
                  <img src="./../assets/icon/3.svg" alt="" />
                </div>
                <div class="title">作战统筹</div>
              </div>
              <div class="item" @click="onApplicationInterface('飞行数据处理')">
                <div class="icon">
                  <img src="./../assets/icon/4.svg" alt="" />
                </div>
                <div class="title">飞行数据处理</div>
              </div>
              <div class="item" @click="onApplicationInterface('系统用户管理')">
                <div class="icon">
                  <img src="./../assets/icon/5.svg" alt="" />
                </div>
                <div class="title">系统用户管理</div>
              </div>
              <div class="item" @click="onApplicationInterface('飞行数据处理')">
                <div class="icon">
                  <img src="./../assets/icon/6.svg" alt="" />
                </div>
                <div class="title">飞行数据处理</div>
              </div>
            </div>
            <div class="paging">
              <div class="wrap">
                <div class="dot current"></div>
                <div class="dot"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      swiper: null,
      tabIndex: 0,
      dialogVisible: false,
      operationStatus: "",
      appList: [
        {
          name: "反坦克计划",
          icon: require("@/assets/icon/1.svg"),
          selected: true,
        },
        {
          name: "计划管理",
          icon: require("@/assets/icon/2.svg"),
        },
        {
          name: "作战统筹",
          icon: require("@/assets/icon/3.svg"),
        },
        {
          name: "飞行数据处理",
          icon: require("@/assets/icon/4.svg"),
        },
        {
          name: "系统用户管理",
          icon: require("@/assets/icon/5.svg"),
        },
        {
          name: "飞行数据处理",
          icon: require("@/assets/icon/6.svg"),
        },
        {
          name: "数据管理工具",
          icon: require("@/assets/icon/7.svg"),
        },
        {
          name: "任务数据包管理",
          icon: require("@/assets/icon/8.svg"),
        },
        {
          name: "任务系统开设",
          icon: require("@/assets/icon/9.svg"),
        },
        {
          name: "任务系统配置",
          icon: require("@/assets/icon/10.svg"),
        },
        {
          name: "任务系统开通",
          icon: require("@/assets/icon/10.svg"),
        },
        {
          name: "任务系统配置",
          icon: require("@/assets/icon/1.svg"),
        },
      ],
      tabs: [
        {
          name: "全部应用",
          selected: true,
        },
        {
          name: "添加应用",
          selected: false,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    handleEdit() {
      this.operationStatus = "edit";
    },
    handleSave() {
      this.operationStatus = "";
    },
    handleDelete(index) {
      this.appList.splice(index, 1);
    },
    onSelected(index) {
      this.tabIndex = index;
      this.tabs.forEach((item) => {
        item.selected = false;
      });
      this.tabs[index].selected = true;
    },
    initSwiper() {
      this.swiper = new window.Swiper(".swiper-container", {
        pagination: {
          el: ".swiper-pagination",
        },
      });
    },
    onApplicationInterface(name) {
      this.$tis.bus.applicationInterface = {
        visible: true,
        name,
      };
    },
    closeDialog() {
      this.dialogVisible = false;
    },

    showFloatingwindow() {},
  },
  computed: {
    controlPanelPadVisible() {
      return this.$tis.bus.controlPanelPadVisible;
    },
    styleHighlight() {
      return (index) => {
        let remainder = index % 2;
        if ((index < 6 && !!remainder) || (index >= 6 && !remainder)) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  watch: {
    controlPanelPadVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.initSwiper();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.control-panel-pad {
  position: fixed;
  top: 0px;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.85);
  z-index: 91;
  .wrap {
    width: 1100px;
    margin: auto;
  }
  .head {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    height: 64px;
    line-height: 64px;
    .tabs {
      display: flex;
      .item {
        font-size: 32px;
        color: #fff;
        opacity: 0.5;
        margin-right: 15px;
        padding: 0px 30px;
        width: 206px;
        box-sizing: border-box;

        .label {
        }
        &.selected {
          opacity: 1;
          // color: #f7c829;
          // background: #f7c829;
          // border-radius: 1px;
          border-bottom: 6px solid #f7c829;
        }
      }
    }
    .right {
      height: 61px;
      width: 456px;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.15);
      .input {
        display: flex;
        align-items: center;
        img {
          height: 30px;
          opacity: 0.6;
          margin: 0 15px;
        }
      }
      input {
        width: 100%;
        height: 61px;
        font-size: 23px;
        line-height: 61px;
        padding: 0 7px;
        background: none;
        color: #fff;
      }
    }
  }
  .content {
    margin-top: 46px;
    .list {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      width: 100%;
      position: relative;
      height: 380px;
      .row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .item {
          width: 166px;
          height: 166px;
          // margin: 7px;
          margin-bottom: 16px;
          background: rgba(255, 255, 255, 0.1);
          position: relative;
          margin-right: 20px;
          &:nth-of-type(6n) {
            margin-right: 0;
          }
          .close-btn {
            position: absolute;
            font-size: 28px;
            right: 6px;
            top: 4px;
          }
          .icon {
            width: 60px;
            height: 83px;
            margin: auto;
            margin-top: 15px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            font-size: 23px;
            color: #ffffff;
            letter-spacing: 0;
            margin-top: 15px;
          }

          &.selected {
            position: relative;
            &::after {
              content: "";
              width: 20px;
              height: 12px;
              background-image: url(../assets/sanjiao.png);
              background-repeat: no-repeat;
              background-size: cover;
              transform: rotate(226deg);
              position: absolute;
              top: 10px;
              right: 10px;
            }
          }
        }
        .highlight {
          background: rgba(255, 255, 255, 0.25);
        }
      }
    }

    .paging {
      margin-top: 30px;
      .wrap {
        display: flex;
        justify-content: center;
        .dot {
          width: 12px;
          height: 12px;
          margin: 0 7px;
          background: #fff;
          opacity: 0.3;
          border-radius: 12px;
          &.current {
            opacity: 1;
          }
        }
      }
    }
  }
  .operation {
    button {
      height: 60px;
      font-size: 18px;
      color: #ffffff;
      padding: 0 60px;
      margin-right: 20px;
      background: none;
    }
  }
}
.dialog {
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .mask {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
  }
  .dialog-body {
    width: 942px;
    padding-bottom: 46px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 9);
    border-radius: 6px;
  }
  .title {
    font-size: 27px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    margin-top: 61px;
    height: 38px;
  }
  .content {
    margin-top: 38px;
  }
  &.dialog2 {
    .list {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      width: 669px;
      margin: auto;
      .item {
        width: 206px;
        height: 206px;
        margin: 7px;
        background: rgba(255, 255, 255, 0.1);
        &:nth-last-child(even) {
          background: rgba(255, 255, 255, 0.25);
        }
        .icon {
          width: 77px;
          height: 123px;
          margin: auto;
          margin-top: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 23px;
          color: #ffffff;
          letter-spacing: 0;
          margin-top: 15px;
        }
      }
    }
    .paging {
      margin-top: 61px;
      .wrap {
        display: flex;
        justify-content: center;
        width: 100%;
        .dot {
          width: 12px;
          height: 12px;
          margin: 0 7px;
          background: #fff;
          border-radius: 12px;
          opacity: 0.3;
          &.current {
            opacity: 1;
          }
        }
      }
    }
  }
}

.swiper-container {
  padding-bottom: 65px;

  ::v-deep .swiper-pagination-bullet {
    background: #fff;
    width: 12px;
    height: 12px;
  }
}
::v-deep .my-swipe {
  padding-bottom: 30px;
  .van-swipe__indicator {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }
}
</style>
