<template>
  <div class="w-dialog w-dialog1" v-if="floatingWindowVisible">
    <div class="title">
      <div class="left">浮窗提示</div>
      <div class="right close-btn" @click="onClose"></div>
    </div>
    <div class="angle-mark left-top"></div>
    <div class="angle-mark left-bottom"></div>
    <div class="angle-mark right-top"></div>
    <div class="angle-mark right-bottom"></div>
    <div class="body-content">
      <div class="list">
        <div class="item">
          <div class="row row1">
            00001某某车
          </div>
          <div class="row row2">
            S 121°10′ 55.648″ W 121°10′ 55.648″
          </div>
        </div>
        <div class="item">
          <div class="row row1">
            00001某某车
          </div>
          <div class="row row2">
            S 121°10′ 55.648″ W 121°10′ 55.648″
          </div>
        </div>
        <div class="item">
          <div class="row row1">
            00001某某车
          </div>
          <div class="row row2">
            S 121°10′ 55.648″ W 121°10′ 55.648″
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    floatingWindowVisible() {
      return this.$tis.bus.floatingWindowVisible;
    },
  },
};
</script>
<style lang="scss" scoped>
.w-dialog {
  position: fixed;
  width: 462px;
  background: rgba(0, 0, 0, 0.8);
  padding: 0 18px;
  z-index: 999;
  .angle-mark {
    border: 3px solid #f7dd29;
    width: 15px;
    height: 15px;
    position: absolute;

    &.left-top {
      top: -1px;
      left: -1px;
      border-bottom: none;
      border-right: none;
    }
    &.right-top {
      right: -1px;
      top: -1px;
      border-left: none;
      border-bottom: none;
    }
    &.left-bottom {
      bottom: -1px;
      left: -1px;
      border-top: none;
      border-right: none;
    }
    &.right-bottom {
      right: -1px;
      bottom: -1px;
      border-left: none;
      border-top: none;
    }
  }
  .title {
    height: 61px;
    line-height: 61px;
    font-size: 23px;
    color: #f7dd29;
    display: flex;
    justify-content: space-between;
    .right.close-btn {
      position: relative;
      width: 30px;
      text-align: center;
      &::after {
        content: "";
        height: 1px;
        width: 15px;
        background: #f7dd29;
        position: absolute;
        top: 50%;
      }
    }
  }
  &.w-dialog1 {
    width: 462px;
    top: 107px;
    right: 38px;
    padding-bottom: 23px;
    .item {
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #ffcb00;
      margin-bottom: 18px;
      padding: 15px;
      .row1 {
        font-size: 21px;
        color: #ffffff;
      }
      .row2 {
        font-family: EurostileRegular;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
}
</style>
