<template>
  <div>
    <!-- 页面跳转 -->
    <LoginPageMobile
      v-if="!hasLogin"
      @login-success="login"
      @login-fail="login"
    />
    <BasicLayout v-show="hasLogin">
      <StatusBarContainerMobile slot="status-bar" />
      <!-- 控制面板，全部应用 -->
      <ControlPanelMobile />
      <!-- 左下角 浮动工具 -->
      <FloatingToolPad />
      <!-- 全部应用里面的添加浮窗 -->
      <FloatingWindowPad />
      <!-- 应用界面  设置等 -->
      <ApplicationInterfacePad>
        <!-- 消息设置 -->
        <MessageSettingPad />
        <!-- 报警页面 -->
        <AlarmPad />
        <!-- 消息中心 -->
        <MessageCenterPad />
      </ApplicationInterfacePad>
      <!-- 搜索 -->
      <SearchBoxPad />
	  <VoiceBoxContainerMobile />
      <!-- 首页抽屉消息 -->
      <MessageBoxPad />
      <!-- 消息回复 -->
      <MessageReplyDialogPad />
    </BasicLayout>
  </div>
</template>

<script>
import BasicLayout from "./layouts/BasicLayout";
import StatusBarContainerMobile from "./layouts/StatusBarContainerMobile";
import ControlPanelMobile from "./../components/ControlPanelMobile.vue";
import FloatingToolPad from "./../components/FloatingToolMobile.vue";
import FloatingWindowPad from "./../components/FloatingWindowMobile.vue";
import ApplicationInterfacePad from "./../components/ApplicationInterfaceMobile.vue";
import AlarmPad from "./../components/AlarmMobile.vue";
import SearchBoxPad from "./../components/SearchBoxMobile.vue";
import MessageBoxPad from "./../components/MessageBoxMobile.vue";
import MessageSettingPad from "./../components/MessageSettingMobile.vue";
import MessageCenterPad from "./../components/MessageCenterMobile.vue";
import MessageReplyDialogPad from "./../components/MessageReplyDialogMobile.vue";
import LoginPageMobile from "../components/LoginPageMobile.vue";
import VoiceBoxContainerMobile from './layouts/VoiceBoxContainerMobile.vue'

export default {
  components: {
	VoiceBoxContainerMobile,
    LoginPageMobile,
    ControlPanelMobile,
    FloatingToolPad,
    MessageCenterPad,
    BasicLayout,
    StatusBarContainerMobile,
    FloatingWindowPad,
    ApplicationInterfacePad,
    AlarmPad,
    SearchBoxPad,
    MessageBoxPad,
    MessageSettingPad,
    MessageReplyDialogPad,
  },
  data() {
    return {
      hasLogin: sessionStorage.getItem('token') === 'success',
    };
  },
  mounted() {
    this.$tis.bus.deviceType = "mobile";
  },
  computed: {
    alertSettingPageVisible() {
      return this.$tis.bus.alertSettingPageVisible;
    },
  },
  methods: {
    login({ username, password }) {
      console.log("用户名：", username);
      console.log("密码：", password);
      // 登录逻辑
      this.hasLogin = true;
    },
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
</style>
