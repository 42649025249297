<template>
  <div class="messagebox" v-if="visibleMessageBox" v-clickoutside="handleClose">
    <div class="wrapper">
      <div class="row head">
        <div class="col">
          <tis-dropdown @command="onSelected">
            <span class="el-dropdown-link">
              {{ sodatalist[type].soname }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <tis-dropdown-menu slot="dropdown" class="message-dropdown-menu">
              <tis-dropdown-item
                v-for="(item, index) in sodatalist"
                :key="index"
                :command="item"
              >
                {{ item.soname }}
              </tis-dropdown-item>
            </tis-dropdown-menu>
          </tis-dropdown>
        </div>
        <div class="col right" @click="handleClear">
          <div>清空</div>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <m-swipe-cell>
            <m-cell>
              <div class="row title">
                <div class="col col1">
                  <div class="tag">{{ item.tag }}</div>
                  <div class="t">{{ item.title }}</div>
                </div>
                <div class="col col2">{{ item.time }}</div>
              </div>
              <div class="row row2">
                {{ item.content }}
              </div>
            </m-cell>
            <template slot="right">
              <div class="o-btn">
                <m-button square text="回复" @click="onReply(index)" />
                <m-button square text="删除" @click="onDelete(index)" />
              </div>
            </template>
          </m-swipe-cell>
        </div>
      </div>
      <div class="footer">
        <div class="m-center" @click="showMessageCenter">
          <img src="~@/assets/message-btn1.png" alt="" srcset="" />
        </div>
        <div class="setting-btn" @click="onMessageSetting">
          <img src="~@/assets/message-btn2.png" alt="" srcset="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const clickoutside = {
  // 初始化指令
  bind(el, binding) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    setTimeout(() => {
      document.addEventListener("click", documentHandler);
    });
  },
  update() {},
  unbind(el) {
    // 解除事件监听
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};
export default {
  name: "MessageBox",
  directives: { clickoutside },
  data() {
    return {
      type: 0,
      sodatalist: [
        {
          id: 0,
          soname: "全部",
        },
        {
          id: 1,
          soname: "篮网队",
        },
        {
          id: 2,
          soname: "火箭队",
        },
      ],
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleClose(e) {
      let el = document.querySelector(".message-reply-dialog");
      if (
        !el ||
        (el &&
          !document.querySelector(".message-reply-dialog").contains(e.target))
      ) {
        this.$tis.bus.visibleMessageBox = false;
      }
    },
    onSelected(data) {
      this.type = data.id;
      if (data.id === 0) {
        this.getList();
      } else {
        this.list = [];
      }
    },
    onDelete(index) {
      this.list.splice(index, 1);
    },
    onReply(index) {
      this.$tis.bus.messageDetailVisible = true;
    },
    getList() {
      this.list = [
        {
          title: "态势感知1",
          tag: "报警",
          time: "10分钟",
          content:
            "请于2021年5月15日12:00分于x地，于x部队配合，执行x任务，所需资源包…",
        },
        {
          title: "态势感知2",
          tag: "报警",
          time: "10分钟",
          content:
            "请于2021年5月15日12:00分于x地，于x部队配合，执行x任务，所需资源包…",
        },
        {
          title: "态势感知3",
          tag: "报警",
          time: "10分钟",
          content:
            "请于2021年5月15日12:00分于x地，于x部队配合，执行x任务，所需资源包…",
        },
        {
          title: "态势感知4",
          tag: "报警",
          time: "10分钟",
          content:
            "请于2021年5月15日12:00分于x地，于x部队配合，执行x任务，所需资源包…",
        },
      ];
    },
    handleAll() {
      this.getList();
    },
    handleClear() {
      this.list = [];
    },
    onMessageSetting() {
      this.$tis.bus.visibleMessageBox = false;
      this.$tis.bus.registerVisible.messageSettingVisible = true;

      this.$tis.bus.messageSettingVisible = true;
      this.$tis.bus.applicationInterface = {
        visible: true,
        name: "系统设置",
      };
    },
    showMessageCenter() {
      this.$tis.bus.registerVisible.messageCenterVisible = true;

      this.$tis.bus.messageCenterVisible = true;
      this.$tis.bus.applicationInterface = {
        visible: true,
        name: "消息中心",
      };
      this.$tis.bus.visibleMessageBox = false;
    },
  },
  computed: {
    visibleMessageBox() {
      return this.$tis.bus.visibleMessageBox;
    },
  },
};
</script>
<style lang="scss" scoped>
.messagebox {
  font-family: HiraginoSansGB-W6;
  width: 489px;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 80px;
  background: #242423;
  z-index: 91;
  .head {
    height: 74px;
    padding: 0 28px;
    font-size: 23px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ::v-deep .el-dropdown {
      font-size: 23px;
    }
    .right {
      font-size: 21px;
    }
  }
  .list {
    border-top: 1px solid #f0f0f0;
    height: calc(100vh - 195px - 80px);
    overflow-y: auto;
  }
  .item {
    padding: 0px 0;
    font-size: 21px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    background: #2f2f2e;
    ::v-deep .van-cell {
      background: none;
      padding-left: 23px;
      padding-right: 23px;
      &:after {
        background: red;
        content: "";
        display: none;
      }
    }
    .title {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
      .col1 {
        display: flex;
        align-items: center;
      }
      .tag {
        font-size: 18px;
        color: #ffffff;
        height: 27px;
        line-height: 27px;
        background: #cc4e4e;
        margin-right: 16px;
        text-align: center;
        padding: 0 15px;
        font-weight: 100;

        font-family: HiraginoSansGB-W6;
      }
      .t {
        font-size: 21px;
        color: #ffffff;
      }
      .col2 {
        opacity: 0.5;
        font-size: 18px;
        color: #ffffff;
      }
    }
    .row2 {
      opacity: 0.5;
      font-family: HiraginoSansGB-W3;
      font-size: 21px;
      color: #ffffff;
      line-height: 27px;
      padding-bottom: 15px;
    }
    .o-btn {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-left: 1px solid rgba(255, 255, 255, 0.25);
      width: 113px;
      height: 100%;
      flex: 1;
      button {
        flex: 1;
        background: none;
        color: rgba(255, 255, 255, 0.8);
        font-size: 20px;
        margin: 0;
        padding: 0;
        border: none;
        &:nth-of-type(1) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        }
      }
    }
  }
  .footer {
    position: absolute;
    width: 100%;
    bottom: 27px;
    box-sizing: border-box;
    padding: 0 26px;
    display: flex;
    justify-content: space-between;
    .m-center {
      width: 354px;
      height: 61px;
    }
    .setting-btn {
      width: 61px;
      height: 61px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style lang="scss" scoped>
.message-dropdown-menu {
  li {
    font-size: 21px;
    line-height: 35px;
    opacity: 0.6;
  }
}
</style>
